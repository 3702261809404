<template>
    <div class="zt-page-content">
        <div style="margin:20px;text-align:right;">
            <!-- @click="goto('/factory/qyrg/addQyrg')" -->
            <el-button size="small" type="primary" @click="add('企业入规申请')">提交新申请</el-button>
        </div>
        <div class="block" style="height: calc(100% - 92px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="申请编号">
                    </el-table-column>
                    <el-table-column prop="createdAt" label="申请日期">
                        <template slot-scope="scope">
                            <div>{{scope.row.createdAt | moment("YYYY-MM-DD")}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="factory_addr" label="厂房地址">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.factory_addr}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="meter_number" label="用电表号">
                        <template slot-scope="scope">
                            <div>{{scope.row.info.meter_number}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="申请状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.state==1">已保存待提交</div>
                            <div v-if="scope.row.state==2">已提交待审核</div>
                            <div v-if="scope.row.state==3">审核不通过</div>
                            <div v-if="scope.row.state==4">审核通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="meter_number" label="操作">
                        <template slot-scope="scope">
                            <el-button @click="goto('/factory/gov/qyrg/detail/'+scope.row.id)" type="text">查看详情</el-button>
                            <!-- <el-button @click="edit('企业入规申请',scope.row)" type="text">重新提交</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page.pageNumber" :page-size="queryOpt.page.pageCount" :total="total"></el-pagination>
            </div>
        </div>
        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <Detail :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></Detail>
        </el-dialog>
    </div>
</template>

<script>
    import Detail from '../SjfsqDetail'
    import { applyList } from '@/service/factory';
    export default {
        components: {
            Detail
        },
        data() {
            return {
                popVisible: false,
                popTitle: null,
                queryOpt:{
                    where:{
                        type:"join"
                    },
                    page:{
                        pageNumber:1,
                        pageCount:15,
                    },
                    order: [
                        ["createdAt","desc"]
                    ],
                },
                total:0,
                list:[],
                ruleForm:"",
                comp:null,
            }
        },
        async created() {
            this.comp = this.$store.state.loginUser.comp;
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link);
            },
            refreshItems() {
                console.log('refreshItems');
                applyList(this.queryOpt).then(rst => {
                    if (rst && rst.rows.length>0) {
                        this.list = rst.rows;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add(text){
                this.popTitle=text;
                this.popVisible=true;
                this.ruleForm = {
                    type:"join",
                    info:{
                        company_name:this.comp.name,
                        legal_name:this.comp.legal_name,
                        phone:"",
                        factory_addr:"",
                        meter_number:"",
                    },
                    submitNow:true,
                }
            },
            edit(text,item) {
                this.popTitle=text;
                this.popVisible=true;
                this.ruleForm = {
                    type:"join",
                    info:{
                        company_name:this.comp.name,
                        legal_name:this.comp.legal_name,
                        phone:item.info.phone,
                        factory_addr:item.info.factory_addr,
                        meter_number:item.info.meter_number,
                    },
                    submitNow:true,
                }
            },
            del(row) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type:'warning'
                }).then(() => {

                    this.$message.success('删除成功!');
                });
            }
        }
    }
</script>
<style scoped src="../../style.css"></style>
<style scoped>

    div>>> .el-table .cell {
        text-align: center;
    }
    div>>> .el-dialog {
        min-width: 550px;
    }
    div>>> .el-dialog__body {
        background: #F5F5F5;
    }

</style>